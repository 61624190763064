<script>
import QrcodeVue from "qrcode.vue";
import CoreModal from "../atoms/CoreModal.vue";
import { useLeftMenuStore } from "../../store/leftMenu";
import { useSessionStore } from "../../store/session";
import { useTasksStore } from "../../store/tasks";
import CoreMenuItem from "~/components/atoms/CoreMenuItem.vue";
import IconTooltip from "~/components/molecules/IconTooltip.vue";
import ProfileButton from "~/components/molecules/ProfileButton.vue";
import { formatUSD } from "~/utils/helpers";
import WarningWrongChainModal from "~/components/organisms/wallet/WarningWrongChainModal.vue";
import WalletAddress from "@/components/molecules/WalletAddress.vue";
import { PROVIDER_LOGOS } from "~/utils/constants";
import SecurityKeyModal from "@/components/organisms/wallet/SecurityKeyModal";
import User from "~/services/user";
import delegatesMixin from "~/mixins/delegates";
import { useWalletStore } from "~/store/wallet";
import { useAnimationsStore } from "~/store/animations";
import { useThemeStore } from "~/store/theme";

export default {
  components: {
    CoreMenuItem,
    IconTooltip,
    ProfileButton,
    WarningWrongChainModal,
    WalletAddress,
    SecurityKeyModal,
    CoreModal,
    QrcodeVue,
  },
  mixins: [delegatesMixin],
  setup() {
    const leftMenuStore = useLeftMenuStore();
    const sessionStore = useSessionStore();
    const taskStore = useTasksStore();
    const walletStore = useWalletStore();
    const animationsStore = useAnimationsStore();
    const themeStore = useThemeStore();
    return {
      leftMenuStore,
      themeStore,
      animationsStore,
      sessionStore,
      taskStore,
      walletStore,
    };
  },
  data() {
    return {
      companySelect: false,
      wrongChainModal: false,
      securityKeyModal: false,
      riseIdModal: false,
      loadingWallet: false,
    };
  },
  computed: {
    mobileOpen() {
      return this.leftMenuStore.isMobileOpen;
    },
    isDark() {
      return this.themeStore.isDark;
    },
    user() {
      return this.sessionStore.user;
    },
    company() {
      return this.sessionStore.company;
    },
    advanced() {
      return this.sessionStore.advanced;
    },
    hasCompany() {
      return this.sessionStore.hasCompany;
    },
    role() {
      return this.sessionStore.role;
    },
    riseId() {
      return this.sessionStore.riseId;
    },
    tasks() {
      return this.taskStore.items;
    },
    balance() {
      return this.sessionStore.balance;
    },
    hasWalletConnected() {
      return this.walletStore.isActive;
    },
    walletType() {
      return this.walletStore.walletType;
    },
    walletAddress() {
      return this.walletStore.walletAddress;
    },
    wallets() {
      return this.sessionStore.wallets;
    },
    animationsEnabled() {
      return this.animationsStore.animationsEnabled;
    },
    navigationItems() {
      return [
        {
          title: "Dashboard",
          icon: "gauge",
          to: "/dashboard/",
          show: () => true,
        },
        {
          title: "Action Items",
          icon: "tasks",
          to: "/tasks/",
          number: this.tasks?.length,
          show: () => this.tasks?.length > 0,
        },
        {
          title: "Team",
          icon: "users",
          to: "/talent/",
          show: () => this.company !== null && this.role === "client",
        },
        {
          title: "Withdraw",
          icon: "hand-holding-usd",
          to: "/withdraw",
          show: () =>
            this.riseId &&
            this.riseId !== "pending" &&
            this.role === "contractor",
        },
        {
          title: "Invoices",
          icon: "receipt",
          to: "/invoices",
          show: () =>
            this.riseId &&
            this.riseId !== "pending" &&
            this.role === "contractor",
        },
        {
          title: "Fund",
          icon: "hand-holding-usd",
          to: "/fund/",
          show: () => this.company && this.riseId && this.role === "client",
        },
        {
          title: "Clients",
          icon: "users",
          to: "/clients/",
          show: () =>
            this.user &&
            this.user.companies?.length &&
            this.riseId &&
            this.riseId !== "pending" &&
            this.role === "contractor",
        },
        {
          title: "Pay",
          icon: "money-check",
          to: "/payments/management/",
          show: () => this.company && this.riseId && this.role === "client",
        },
        {
          title: "Time Entry",
          icon: "user-clock",
          to: "/payments/timesheets/",
          show: () =>
            this.company &&
            this.riseId &&
            this.riseId !== "pending" &&
            this.role === "contractor",
        },
        {
          title: "Activity",
          icon: "chart-line",
          to: "/payments/transaction-history/",
          show: () => this.company && this.riseId && this.riseId !== "pending",
        },
        {
          title: "Documents",
          icon: "file-contract",
          to: "/documents/",
          show: () => true,
        },
        {
          title: "Rise ID",
          icon: "id-card",
          to: "/riseid/",
          show: () =>
            (this.riseId && this.riseId !== "pending") ||
            (this.role === "contractor" && this.user && this.user.hasKYC),
        },
        {
          title: "Refer & Earn",
          icon: "sack-dollar",
          to: "/referral/",
          show: () =>
            this.riseId && this.riseId !== "pending" && this.role === "client",
        },
      ];
    },
    bottomItems() {
      return [
        {
          title: "Support",
          icon: "info-circle",
          click: () => this.supportButton(),
        },
        {
          title: "Log out",
          icon: "arrow-right-to-bracket",
          click: () => this.logoutButton(),
        },
      ];
    },
    enabledNavigationItems() {
      return this.navigationItems.filter((e) => e.show());
    },
  },
  async mounted() {
    await this.taskStore.fetch();
    await this.sessionStore.me();
    this.sessionStore.getBalance();
    this.getDelegates();
    setInterval(() => {
      if (!this.$route.path.includes("/auth")) this.taskStore.fetch();
    }, 45000);
  },
  methods: {
    async setActiveCompany(id) {
      this.$loader.show();
      try {
        await User.setActiveCompany(id);
        await this.sessionStore.me();
      } catch (e) {
        this.$toast.show({
          type: "danger",
          title: "Could not change company",
          message: e.toString(),
        });
      }
      this.$router.push("/dashboard");
      this.$loader.hide();
    },
    async disconnectWallet() {
      await this.walletStore.DISCONNECT();
    },
    walletTypeLogo() {
      return this.walletType && PROVIDER_LOGOS[this.walletType.toLowerCase()]
        ? PROVIDER_LOGOS[this.walletType.toLowerCase()]
        : "/icons/arbitrum.svg";
    },
    getName(company) {
      if (this.role === "client") {
        if (company?.team?.length > 0)
          return `${company.team} (${company.name})`;
        if (company?.name?.length > 0) return company.name;
      }
      return `${this.user?.firstname} ${this.user?.lastname}`;
    },
    async connect() {
      this.loadingWallet = true;
      try {
        console.log(1);
        await this.walletStore.connectWallet();
        console.log(2);
      } catch (e) {
        console.log(3, e);
        const errorMessage = e?.message?.toString() || e?.toString();
        if (errorMessage.includes("Wrong chainId")) this.wrongChainModal = true;
        else if (!errorMessage.includes("Wallet not authorized"))
          this.$sentryCaptureException(e);
      } finally {
        this.loadingWallet = false;
      }
    },
    formatUSD(value) {
      return formatUSD(value);
    },
    supportButton() {
      window.open("https://help.riseworks.io/en/", "RiseSupport");
    },
    async logoutButton() {
      this.$router.push("/sign-out");
    },
  },
};
</script>

<template>
  <div
    id="left_menu"
    class="fixed left-0 top-0 z-20 flex h-full max-h-screen w-full flex-col border-r border-gray-800 bg-dashboard-bg dark:border-gray-800 dark:bg-dashboard-bg md:relative print:!hidden"
    :class="{ '!h-20 md:!h-full md:overflow-visible': !mobileOpen }"
  >
    <SecurityKeyModal
      v-if="wallets"
      v-model="securityKeyModal"
      :wallet="wallets[0]"
    />
    <WarningWrongChainModal v-model="wrongChainModal" />
    <div
      v-if="animationsEnabled"
      class="pointer-events-none !visible absolute left-0 top-0 h-full w-full overflow-hidden"
      :class="{ block: mobileOpen, 'hidden md:block': !mobileOpen }"
    >
      <lottie-animation
        class="absolute -right-[176px] bottom-0 !h-auto !w-[300vw] md:!w-[1130px]"
        animation-link="/lottie/background.json"
      />
    </div>
    <div
      class="flex-0 z-10 flex h-20 items-center justify-between p-5 md:h-fit md:px-6"
      :class="{ 'border-b border-gray-800 md:border-none': mobileOpen }"
    >
      <div
        class="!visible flex h-14 w-14 cursor-pointer items-center justify-center text-white md:hidden"
        @click="leftMenuStore.setMobileOpen(!mobileOpen)"
      >
        <font-awesome-icon v-show="!mobileOpen" icon="bars" class="!h-6 !w-6" />
        <font-awesome-icon v-show="mobileOpen" icon="times" class="!h-6 !w-6" />
      </div>
      <nuxt-link to="/dashboard">
        <img
          v-show="isDark"
          class="h-[30px] w-auto md:h-[43px]"
          src="/logo/rise-logo-white.svg"
          alt="Rise Works Inc."
        />
        <img
          v-show="!isDark"
          class="h-[30px] w-auto md:h-[43px]"
          src="/logo/rise-logo-white.svg"
          alt="Rise Works Inc."
        />
      </nuxt-link>
      <ProfileButton class="md:hidden" />
      <!-- <CoreThemeSwitch class="hidden md:inline-flex" /> -->
    </div>
    <div
      class="!visible relative z-10 min-h-0 flex-1 overflow-x-auto px-3 pt-2 md:pt-0"
      :class="{ block: mobileOpen, 'hidden md:block': !mobileOpen }"
    >
      <CoreMenuItem
        v-for="item in enabledNavigationItems"
        :key="item.title"
        :icon="item.icon"
        :name="item.title"
        :number="item.number"
        :to="item.to"
        class="mb-1"
      />
    </div>
    <div
      class="flex-0 !visible relative z-10 px-3 pb-6 pt-1"
      :class="{ block: mobileOpen, 'hidden md:block': !mobileOpen }"
    >
      <div
        class="mb-4 rounded-lg px-3 py-4 text-white [background-image:linear-gradient(103deg,_rgba(25,_15,_86,_1),_rgba(39,_123,_150,_1)_48%,_rgba(73,_22,_137,_1))]"
      >
        <div class="relative mb-1 flex items-center justify-between">
          <nuxt-link to="/riseid/" class="min-w-0 flex-1 hover:text-gray-200">
            <div class="flex h-[15px] items-center">
              <div
                class="label-name mr-1 text-[10px] font-bold uppercase text-gray-400"
              >
                Rise Id
              </div>
              <IconTooltip placement="right">
                <template #icon>
                  <font-awesome-icon
                    icon="circle-info"
                    class="relative -top-[2px] !h-[10px] !w-[10px] text-gray-400"
                  />
                </template>
                <div>
                  This is your RiseID which is where all transactions are sent
                  to and from. Your RiseID is your unique identity on blockchain
                  so Rise and other partners can recogize your transactions.
                </div>
              </IconTooltip>
            </div>
            <div class="truncate text-base font-medium">
              {{ getName(company) }}
            </div>
          </nuxt-link>
          <div
            v-if="role === 'client' && company"
            class="flex-0 -mr-3 flex h-[40px] w-[40px] cursor-pointer flex-col items-center justify-center hover:text-gray-200"
            @click="companySelect = !companySelect"
          >
            <font-awesome-icon icon="chevron-up" class="!h-[12px] !w-auto" />
            <font-awesome-icon
              icon="chevron-down"
              class="-mt-1 !h-[12px] !w-auto"
            />
          </div>
          <div
            v-if="companySelect"
            class="absolute -right-3 bottom-14 left-auto w-64 animate-fadeIn_fast rounded-lg bg-gray-900 px-2 py-2 text-sm font-medium text-white transition-all md:-bottom-2 md:left-full md:right-auto"
            @mouseleave="companySelect = false"
          >
            <div
              v-for="item in user ? user.companies : []"
              :key="item.id"
              :class="{ 'bg-gray-800': item.id === company.id }"
              class="mb-2 flex cursor-pointer items-center rounded-lg p-2 last:mb-0 hover:bg-gray-800"
              @click="setActiveCompany(item.id)"
            >
              <div class="min-w-0 flex-1 truncate">
                {{ getName(item) }}
              </div>
              <font-awesome-icon
                v-if="item.id === company.id"
                class="flex-0 ml-2 !h-4 !w-4"
                icon="check"
              />
            </div>
            <nuxt-link
              to="/management/company"
              class="block px-2 py-1 text-sm font-medium text-primary-400 underline hover:text-primary-600"
            >
              View All
            </nuxt-link>
            <div class="mt-3 border-t border-gray-800 pt-3">
              <nuxt-link
                to="/management/company/create/team"
                class="flex cursor-pointer items-center rounded-lg p-2 hover:bg-gray-800"
              >
                <font-awesome-icon
                  class="flex-0 mr-3 !h-[14px] !w-[14px]"
                  icon="user-group"
                />
                <div class="min-w-0 flex-1 truncate">Create new team</div>
              </nuxt-link>
            </div>
          </div>
        </div>
        <div class="mb-1 text-2xl font-bold">
          {{ formatUSD(balance) }}
        </div>
        <div v-if="role !== 'client'" class="flex items-center">
          <font-awesome-icon
            icon="qrcode"
            class="flex-0 mr-3 !h-4 !w-4 cursor-pointer hover:text-gray-200"
            @click="riseIdModal = true"
          />
          <WalletAddress
            class="flex-1 hover:!text-gray-200"
            color="white"
            :address="riseId"
            copyable
          />
        </div>
        <button
          v-if="(!(advanced === false) && !hasWalletConnected) || loadingWallet"
          type="button"
          class="mt-4 flex h-12 w-full items-center rounded-lg bg-gradient-to-r from-[rgba(60,164,224,1)] via-[rgba(78,98,216,1)] to-primary-900 bg-size-200 bg-pos-0 p-3 text-left font-medium text-white transition-all duration-500 hover:bg-pos-100"
          :class="{ 'pointer-events-none': loadingWallet }"
          @click="connect"
        >
          <RiseSpinner
            v-if="loadingWallet"
            class="mr-2 !h-5 !w-5 !fill-gray-100 !text-gray-200/25"
          />
          <font-awesome-icon v-else icon="wallet" class="mr-3 !h-5 !w-5" />

          <p class="min-w-0 flex-1 text-base">Connect wallet</p>
          <IconTooltip
            class="ml-3 flex h-4 w-4 items-center justify-center"
            placement="right"
          >
            <template #icon>
              <font-awesome-icon
                icon="circle-info"
                class="relative !h-4 !w-4"
              />
            </template>
            <div>
              Your Wallet (Owner or Delegate of RiseID) needs to be connected in
              order to perform Blockchain related tasks:
              <ul class="ml-4">
                <li>Create RiseID</li>
                <li>Create Pay Schedule</li>
                <li>Pay</li>
                <li>Fund/Withdraw your balance</li>
              </ul>
            </div>
          </IconTooltip>
        </button>
        <div
          v-else-if="hasWalletConnected && walletType"
          v-tooltip.right="walletAddress"
          class="mt-4 flex h-fit min-h-12 w-full rounded-lg bg-gradient-to-r from-[rgba(60,164,224,1)] to-[rgba(78,98,216,1)] p-3 text-left font-medium text-white"
        >
          <img
            class="mr-3 !h-6 !w-6"
            :src="walletTypeLogo()"
            :alt="walletType"
          />
          <div>
            <p class="min-w-0 flex-1 text-base">
              {{ walletType }}
              {{ walletType.toLowerCase().includes("wallet") ? "" : "wallet" }}
            </p>
            <div class="flex items-center">
              <div
                class="rounded-full bg-green-100 px-[10px] py-[3px] text-xs font-medium text-green-500"
              >
                Connected
              </div>
              <div
                class="cursor-pointer rounded-full px-[10px] py-[2px] text-xs font-medium underline hover:text-gray-200"
                @click="disconnectWallet()"
              >
                Disconnect
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="flex-0 min-h-0">
        <CoreMenuItem
          v-for="item in bottomItems"
          :key="item.title"
          :icon="item.icon"
          :name="item.title"
          class="mb-1"
          @click="item.click"
        />
      </div>
    </div>
    <CoreModal v-if="riseId" v-model="riseIdModal" hide-top-bar>
      <QrcodeVue :value="riseId" class="pb-4" :size="200" />
    </CoreModal>
  </div>
</template>
